<template>
  <v-card>
    <v-card-title class="align-start">
      <h4>定期便会員</h4>

      <v-spacer></v-spacer>

      <v-btn icon small class="mt-n2 me-n3">
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <div
        v-for="(item, index) in dashboardList.mbr_subs"
        :key="index"
        :class="`d-flex align-start ${index > 0 ? 'mt-3' : ''}`"
      >
        <v-row>
          <v-col cols="6">
            <div>
              <h4 class="font-weight-medium ml-1">
                <a
                  href="javascript:void(0);"
                  @click="goMemberSub(item)"
                >{{ item.caption }}</a>
              </h4>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="ms-1">
              <p class="text--primary font-weight-medium mb-1 text-right">
                {{ formatData(item.mbr_cnt) }} 人
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiDotsVertical,
} from '@mdi/js'

export default {
  props: ['dashboardList'],
  data: () => ({
    icons: {
      mdiDotsVertical,
    },
  }),
  computed: {
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
  },
  methods: {
    goMemberSub(item) {
      this.$router.push({
        name: 'member-subs-list',
        query: {
          startDate: item.from,
          endDate: item.to,
        },
      })
    },
  },
}
</script>
