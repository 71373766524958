<template>
  <v-card>
    <v-card-title class="align-start">
      <h4>決済情報</h4>

      <v-spacer></v-spacer>

      <v-btn icon small class="mt-n2 me-n3">
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="dashboardList.settlemnt"
        sort-by
        class="elevation-1"
      >
        <template v-slot:[`item.date`]="{ item }">
          <a
            href="javascript:void(0);"
            @click="goPayment(item)"
          >{{ item.date }}</a>
        </template>
        <template v-slot:[`item.succ_cnt`]="{ item }">
          <label>{{ formatData(item.succ_cnt) }}</label>
        </template>
        <template v-slot:[`item.succ_amount`]="{ item }">
          <label>{{ formatData(item.succ_amount) }}</label>
        </template>
        <template v-slot:[`item.fail_cnt`]="{ item }">
          <label :class="item.fail_cnt > 0 ? 'error--text' : ''">{{ formatData(item.fail_cnt) }}</label>
        </template>
        <template v-slot:[`item.fail_amount`]="{ item }">
          <label :class="item.fail_amount > 0 ? 'error--text' : ''">{{ formatData(item.fail_amount) }}</label>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiDotsVertical,
} from '@mdi/js'

export default {
  props: ['dashboardList'],
  data: () => ({
    icons: {
      mdiDotsVertical,
    },
    headers: [
      {
        text: '日付',
        value: 'date',
        align: 'left',
        width: '20%',
        fixed: true,
      },
      {
        text: '成功数',
        value: 'succ_cnt',
        align: 'right',
        width: '20%',
        fixed: true,
      },
      {
        text: '成功金額',
        align: 'right',
        sortable: true,
        value: 'succ_amount',
        width: '20%',
        fixed: true,
      },
      {
        text: '失敗数',
        value: 'fail_cnt',
        width: '20%',
        align: 'right',
        sortable: true,
        fixed: true,
      },
      {
        text: '失敗金額',
        value: 'fail_amount',
        width: '20%',
        align: 'right',
        sortable: true,
        fixed: true,
      },
    ],
  }),
  computed: {
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
  },
  methods: {
    goPayment(item) {
      this.$router.push({
        name: 'transactions-list',
        query: {
          startDate: item.date,
          endDate: item.date,
        },
      })
    },
  },
}
</script>
