<template>
  <v-card>
    <v-card-title class="align-start">
      <h4>出荷予定</h4>

      <v-spacer></v-spacer>

      <v-btn icon small class="mt-n2 me-n3">
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <div
        v-for="(item, index) in dashboardList.delv_estimated"
        :key="index"
        :class="`d-flex align-start ${index > 0 ? 'mt-4' : ''}`"
      >
        <v-card-text>
          <h3>{{ item.date }}</h3>
          <div
            v-for="(items, index) in item.list"
            :key="index"
            :class="`d-flex align-start ${index > 0 ? 'mt-3' : ''}`"
          >
            <v-row>
              <v-col cols="8">
                <div class="d-flex flex-grow-1">
                  <v-avatar
                    rounded
                    size="50"
                    color="#5e56690a"
                    class="me-4"
                  >
                    <v-img :src="items.images ? `${items.imgURL_prefix}/${items.images.split('\&quot;')[1]}` : ''" height="100%"></v-img>
                  </v-avatar>
                  <div>
                    <h4 class="font-weight-medium ml-1">
                      <a
                        href="javascript:void(0);"
                        @click="goEstimate(item,items)"
                      >{{ items.product_code }}</a>
                      <p>{{ items.product_name }}</p>
                    </h4>
                  </div>
                </div>
              </v-col>
              <v-col cols="4">
                <div class="ms-1">
                  <p class="text--primary font-weight-medium mb-1 text-right">
                    {{ formatData(items.cnt) }} 個
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

/* eslint-disable */
import {
  mdiDotsVertical,
} from '@mdi/js'
/* eslint-disable */

export default {
  props:['dashboardList'],
  data: () => ({
    icons: {
      mdiDotsVertical,
    },
    memberSubList: [],
  }),
  computed: {
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
  },
  methods: {
    goEstimate(item,items) {
      this.$router.push({
        name: 'shippingestimated-list',
        query: {
          product_code: items.product_code,
          start_date: item.date,
        },
      })
    },
  },
}
</script>
