<template>
  <div id="dashboard-list">
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-card>
          <v-card-title class="align-start">
            <h4>今週売上</h4>
            <v-spacer></v-spacer>

            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text
            class="my-5"
          >
            <div class="d-flex align-center">
              <h1 class="text-3xl font-weight-semibold">
                ￥{{ dashboardList.sales_cw ? formatData(dashboardList.sales_cw.order_total) : '' }}
              </h1>

              <div class="d-flex align-center mb-n3">
                <v-icon size="40" :color="isWeekSaleUp ? 'success' : 'error'">
                  {{ isWeekSaleUp ? icons.mdiMenuUp : icons.mdiMenuDown }}
                </v-icon>
                <span :class="`${isWeekSaleUp ? 'success--test' : 'error--text'} text-base font-weight-medium ms-n2`">{{ weekSaleCompare }}%</span>
              </div>
            </div>

            <h4 class="mt-2 font-weight-medium">
              先週の ￥{{ dashboardList.sales_lw?formatData(dashboardList.sales_lw.order_total): '' }} と比較して
            </h4>
          </v-card-text>
          <v-card-text>
            <div
              v-for="(item, index) in cwList"
              :key="index"
              :class="`d-flex align-start ${index > 0 ? 'mt-5' : ''}`"
            >
              <div class="d-flex flex-grow-1">
                <v-avatar
                  rounded
                  size="50"
                  color="#5e56690a"
                  class="me-4"
                >
                  <v-img :src="item.image" height="100%"></v-img>
                </v-avatar>
                <v-row>
                  <v-col cols="7">
                    <div>
                      <h4 class="font-weight-medium ml-1">
                        <a
                          href="javascript:void(0);"
                          @click="goWeekProduct(item)"
                        >{{ item.product_name }}</a>
                      </h4>
                    </div>
                  </v-col>
                  <v-col cols="5">
                    <div class="ms-1">
                      <p class="text--primary font-weight-medium mb-1 text-right text-no-wrap">
                        ￥{{ formatData(item.order_amount) }}
                      </p>
                      <p class="text-xs ml-1 text-right text-no-wrap">
                        {{ formatData(item.order_tip) }} * {{ formatData(item.cnt) }} 個
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title class="align-start">
            <h4>今月売上</h4>
            <v-spacer></v-spacer>

            <v-btn icon small class="me-n3 mt-n2">
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="my-5">
            <div class="d-flex align-center">
              <h1 class="text-3xl font-weight-semibold">
                ￥{{ dashboardList.sales_cm ? formatData(dashboardList.sales_cm.order_total) : '' }}
              </h1>

              <div class="d-flex align-center mb-n3">
                <v-icon size="40" :color="isMonthSaleUp ? 'success' : 'error'">
                  {{ isMonthSaleUp ? icons.mdiMenuUp : icons.mdiMenuDown }}
                </v-icon>
                <span :class="`${isMonthSaleUp ? 'success--test' : 'error--text'} text-base font-weight-medium ms-n2`">{{ monthSaleCompare }}%</span>
              </div>
            </div>

            <h4 class="mt-2 font-weight-medium">
              先月の ￥{{ dashboardList.sales_lm?formatData(dashboardList.sales_lm.order_total): '' }} と比較して
            </h4>
          </v-card-text>
          <v-card-text>
            <div
              v-for="(item, index) in cmList"
              :key="index"
              :class="`d-flex align-start ${index > 0 ? 'mt-5' : ''}`"
            >
              <div class="d-flex flex-grow-1">
                <v-avatar
                  rounded
                  size="50"
                  color="#5e56690a"
                  class="me-4"
                >
                  <v-img :src="item.image" height="100%"></v-img>
                </v-avatar>
                <v-row>
                  <v-col cols="7">
                    <div>
                      <h4 class="font-weight-medium ml-1">
                        <a
                          href="javascript:void(0);"
                          @click="goMonthProduct(item)"
                        >{{ item.product_name }}</a>
                      </h4>
                    </div>
                  </v-col>
                  <v-col cols="5">
                    <div class="ms-1">
                      <p class="text--primary font-weight-medium mb-1 text-right text-no-wrap">
                        ￥{{ formatData(item.order_amount) }}
                      </p>
                      <p class="text-xs ml-1 text-right text-no-wrap">
                        {{ formatData(item.order_tip) }} * {{ formatData(item.cnt) }} 個
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title class="align-start">
            <h4>第{{ dashboardList.sales_cq ? dashboardList.sales_cq.quater_no : '' }}四半期売上</h4>
            <v-spacer></v-spacer>

            <v-btn icon small class="me-n3 mt-n2">
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="my-5">
            <div class="d-flex align-center">
              <h1 class="text-3xl font-weight-semibold">
                ￥{{ dashboardList.sales_cq ? formatData(dashboardList.sales_cq.order_total) : '' }}
              </h1>

              <div class="d-flex align-center mb-n3">
                <v-icon size="40" :color="isQuarterSaleUp ? 'success' : 'error'">
                  {{ isQuarterSaleUp ? icons.mdiMenuUp : icons.mdiMenuDown }}
                </v-icon>
                <span :class="`${isQuarterSaleUp ? 'success--test' : 'error--text'} text-base font-weight-medium ms-n2`">{{ quarterSaleCompare }}%</span>
              </div>
            </div>

            <h4 class="mt-2 font-weight-medium">
              第{{ dashboardList.sales_lq ? dashboardList.sales_lq.quater_no : '' }}四半期売上の ￥{{ dashboardList.sales_lq ? formatData(dashboardList.sales_lq.order_total): '' }} と比較して
            </h4>
          </v-card-text>
          <v-card-text>
            <div
              v-for="(item, index) in cqList"
              :key="index"
              :class="`d-flex align-start ${index > 0 ? 'mt-5' : ''}`"
            >
              <div class="d-flex flex-grow-1">
                <v-avatar
                  rounded
                  size="50"
                  color="#5e56690a"
                  class="me-4"
                >
                  <v-img :src="item.image" height="100%"></v-img>
                </v-avatar>
                <v-row>
                  <v-col cols="7">
                    <div>
                      <h4 class="font-weight-medium ml-1">
                        <a
                          href="javascript:void(0);"
                          @click="goQuarterProduct(item)"
                        >{{ item.product_name }}</a>
                      </h4>
                    </div>
                  </v-col>
                  <v-col cols="5">
                    <div class="ms-1">
                      <p class="text--primary font-weight-medium mb-1 text-right text-no-wrap">
                        ￥{{ formatData(item.order_amount) }}
                      </p>
                      <p class="text-xs ml-1 text-right text-no-wrap">
                        {{ formatData(item.order_tip) }} * {{ formatData(item.cnt) }} 個
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4">
        <MemberSub
          :dashboard-list="dashboardList"
        ></MemberSub>
      </v-col>
      <v-col cols="12" sm="8">
        <Settlemnt
          :dashboard-list="dashboardList"
        ></Settlemnt>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <DelvEstimated
          :dashboard-list="dashboardList"
        ></DelvEstimated>
      </v-col>
      <v-col cols="12" sm="6">
        <DelvConfirmed
          :dashboard-list="dashboardList"
        ></DelvConfirmed>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'

import {
  mdiDotsVertical,
  mdiMenuUp,
  mdiMenuDown,
} from '@mdi/js'

import MemberSub from './MemberSub.vue'
import Settlemnt from './Settlemnt.vue'
import DelvEstimated from './DelvEstimated.vue'
import DelvConfirmed from './DelvConfirmed.vue'

export default {
  components: {
    MemberSub,
    Settlemnt,
    DelvEstimated,
    DelvConfirmed,
  },
  data: () => ({
    icons: {
      mdiDotsVertical,
      mdiMenuUp,
      mdiMenuDown,
    },
    cwList: [],
    weekSaleCompare: 0,
    isWeekSaleUp: true,
    cmList: [],
    monthSaleCompare: 0,
    isMonthSaleUp: true,
    cqList: [],
    quarterSaleCompare: 0,
    isQuarterSaleUp: true,
  }),
  computed: {
    ...mapState('dashboardStore', ['dashboardList']),
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
  },
  created() {
    this.loadData()
  },
  destroyed() {
    this.clearDashboardList()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('dashboardStore', ['loadDashboardList']),
    ...mapMutations('dashboardStore', ['clearDashboardList']),

    loadData() {
      this.setOverlayStatus(true)
      this.loadDashboardList(this.$route.query).then(() => {
        this.getWeekList()
        this.getMonthList()
        this.getQuarterList()
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },

    goWeekProduct(item) {
      if (item.product_type === 2) {
        this.$router.push({
          name: 'order-set-list',
          query: {
            set_code: item.product_code,
            start_date: this.dashboardList.sales_cw ? this.dashboardList.sales_cw.startDate : '',
          },
        })
      } else if (item.product_type === 3) {
        this.$router.push({
          name: 'order-subs-list',
          query: {
            subs_code: item.product_code,
            start_date: this.dashboardList.sales_cw ? this.dashboardList.sales_cw.startDate : '',
          },
        })
      } else {
        console.log('no type')
      }
    },
    goMonthProduct(item) {
      if (item.product_type === 2) {
        this.$router.push({
          name: 'order-set-list',
          query: {
            set_code: item.product_code,
            start_date: this.dashboardList.sales_cm ? this.dashboardList.sales_cm.startDate : '',
          },
        })
      } else if (item.product_type === 3) {
        this.$router.push({
          name: 'order-subs-list',
          query: {
            subs_code: item.product_code,
            start_date: this.dashboardList.sales_cm ? this.dashboardList.sales_cm.startDate : '',
          },
        })
      } else {
        console.log('no type')
      }
    },
    goQuarterProduct(item) {
      if (item.product_type === 2) {
        this.$router.push({
          name: 'order-set-list',
          query: {
            set_code: item.product_code,
            start_date: this.dashboardList.sales_cq ? this.dashboardList.sales_cq.startDate : '',
          },
        })
      } else if (item.product_type === 3) {
        this.$router.push({
          name: 'order-subs-list',
          query: {
            subs_code: item.product_code,
            start_date: this.dashboardList.sales_cq ? this.dashboardList.sales_cq.startDate : '',
          },
        })
      } else {
        console.log('no type')
      }
    },

    getWeekList() {
      if (this.dashboardList.sales_cw && this.dashboardList.sales_lw) {
        this.cwList = [...this.dashboardList.sales_cw.list]
        if (this.dashboardList.sales_lw.order_total === 0 || (this.dashboardList.sales_lw.order_total === 0 && this.dashboardList.sales_cw.order_total === 0)) {
          this.isWeekSaleUp = true
          this.weekSaleCompare = 0
        } else if (this.dashboardList.sales_cw.order_total === 0) {
          this.isWeekSaleUp = false
          this.weekSaleCompare = 0
        } else {
          this.weekSaleCompare = ((Math.abs(this.dashboardList.sales_cw.order_total - this.dashboardList.sales_lw.order_total) / this.dashboardList.sales_lw.order_total) * 100).toFixed(2)
          if ((this.dashboardList.sales_cw.order_total - this.dashboardList.sales_lw.order_total) < 0) {
            this.isWeekSaleUp = false
          } else {
            this.isWeekSaleUp = true
          }
        }
        this.dashboardList.sales_cw.list.forEach((cw, i) => {
          if (cw.images) {
            this.cwList[i].image = `${cw.imgURL_prefix}/${cw.images.split('"')[1].replace(/^\//g, '')}`
          } else {
            this.cwList[i].image = ''
          }
        })
      } else {
        console.log('not sales_cw or sales_lw')
      }
    },

    getMonthList() {
      if (this.dashboardList.sales_cm && this.dashboardList.sales_lm) {
        this.cmList = [...this.dashboardList.sales_cm.list]
        if (this.dashboardList.sales_lm.order_total === 0 || (this.dashboardList.sales_lm.order_total === 0 && this.dashboardList.sales_cm.order_total === 0)) {
          this.isMonthSaleUp = true
          this.monthSaleCompare = 0
        } else if (this.dashboardList.sales_cm.order_total === 0) {
          this.isMonthSaleUp = false
          this.monthSaleCompare = 0
        } else {
          this.monthSaleCompare = ((Math.abs(this.dashboardList.sales_cm.order_total - this.dashboardList.sales_lm.order_total) / this.dashboardList.sales_lm.order_total) * 100).toFixed(2)
          if ((this.dashboardList.sales_cm.order_total - this.dashboardList.sales_lm.order_total) < 0) {
            this.isMonthSaleUp = false
          } else {
            this.isMonthSaleUp = true
          }
        }
        this.dashboardList.sales_cm.list.forEach((cm, i) => {
          if (cm.images) {
            console.log('images', cm.images)
            this.cmList[i].image = `${cm.imgURL_prefix}/${cm.images.split('"')[1].replace(/^\//g, '')}`
          } else {
            this.cmList[i].image = ''
          }
        })
      } else {
        console.log('not sales_cm or sales_lm')
      }
    },

    getQuarterList() {
      if (this.dashboardList.sales_cq && this.dashboardList.sales_lq) {
        this.cqList = [...this.dashboardList.sales_cq.list]
        if (this.dashboardList.sales_lq.order_total === 0 || (this.dashboardList.sales_lq.order_total === 0 && this.dashboardList.sales_cq.order_total === 0)) {
          this.isQuarterSaleUp = true
          this.quarterSaleCompare = 0
        } else if (this.dashboardList.sales_cq.order_total === 0) {
          this.isQuarterSaleUp = false
          this.quarterSaleCompare = 0
        } else {
          this.quarterSaleCompare = ((Math.abs(this.dashboardList.sales_cq.order_total - this.dashboardList.sales_lq.order_total) / this.dashboardList.sales_lq.order_total) * 100).toFixed(2)
          if ((this.dashboardList.sales_cq.order_total - this.dashboardList.sales_lq.order_total) < 0) {
            this.isQuarterSaleUp = false
          } else {
            this.isQuarterSaleUp = true
          }
        }
        this.dashboardList.sales_cq.list.forEach((cq, i) => {
          if (cq.images) {
            this.cqList[i].image = `${cq.imgURL_prefix}/${cq.images.split('"')[1].replace(/^\//g, '')}`
          } else {
            this.cqList[i].image = ''
          }
        })
      } else {
        console.log('not sales_cq or sales_lq')
      }
    },
  },
}
</script>
