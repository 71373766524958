var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"align-start"},[_c('h4',[_vm._v("決済情報")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-n2 me-n3",attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dashboardList.settlemnt,"sort-by":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.goPayment(item)}}},[_vm._v(_vm._s(item.date))])]}},{key:"item.succ_cnt",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(_vm.formatData(item.succ_cnt)))])]}},{key:"item.succ_amount",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(_vm.formatData(item.succ_amount)))])]}},{key:"item.fail_cnt",fn:function(ref){
var item = ref.item;
return [_c('label',{class:item.fail_cnt > 0 ? 'error--text' : ''},[_vm._v(_vm._s(_vm.formatData(item.fail_cnt)))])]}},{key:"item.fail_amount",fn:function(ref){
var item = ref.item;
return [_c('label',{class:item.fail_amount > 0 ? 'error--text' : ''},[_vm._v(_vm._s(_vm.formatData(item.fail_amount)))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }